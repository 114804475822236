export default {
  cake: {
    56: '0x481F0557FB3BB5eE461FD47F287b1ca944aD89bc',
    97: '0xCf11ddcd9b0f6e9C70aAFf9F8D3548A8B13C620e',
  },
  syrup: {
    56: '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
    97: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
  },
  masterChef: {
    56: '0xB8692D7768cC70C8309B669c1f5a3d856909ADB8',
    97: '0x9bE9406FC8dD85974cA0F24CedE6f62e1A9D312D',
  },
  sousChef: {
    56: '0x63621A878f6eae37DC5BdEDB2B828D9E3747b0f4',
    97: '0xd388A7BA0e7CF5833304E9fbe824107Efa40f2f1',
  },
  wbnb: {
    56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
  },
  lottery: {
    56: '0x99c2EcD51d52c036B00130d882Bc65f20Fdecf9f',
    97: '0x3C3f2049cc17C136a604bE23cF7E42745edf3b91',
  },
  lotteryNFT: {
    56: '0x8175c10383511b3a1C68f9dB222dc14A19CC950e',
    97: '0x5e74094Cd416f55179DBd0E45b1a8ED030e396A1',
  },
  mulltiCall: {
    56: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
    97: '0x8A5eFF2BDb0b2B64160cFCFb9001e70f34293A16',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0x457439cd966C0adCC9160DfD9DeEdc265F5ce8EA',
  },
  ust: {
    56: '0x55d398326f99059ff775485246999027b3197955',
    97: '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
  },
  pancakeProfile: {
    56: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    97: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0x94b4188D143b9dD6bd7083aE38A461FcC6AAd07E',
    97: '0x8e0b57fD526d3540322d29dE060B21d43b857699',
  },
  eth: {
    56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    97: '0xB30f62FF4E006a69D034BF50Ab51E099E518bb30',
  },
  claimRefund: {
    56: '',
    97: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
  },
  pointCenterIfo: {
    56: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    97: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
  },
  bunnySpecial: {
    56: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    97: '0xFee8A195570a18461146F401d6033f5ab3380849',
  },
}
