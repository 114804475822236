import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'PURE',
    stakingTokenName: QuoteToken.PURE,
    stakingTokenAddress: '0x481F0557FB3BB5eE461FD47F287b1ca944aD89bc',
    contractAddress: {
      56: '0x63621A878f6eae37DC5BdEDB2B828D9E3747b0f4',
      97: '0xd388A7BA0e7CF5833304E9fbe824107Efa40f2f1',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://pureswap.finance/',
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
  {
    sousId: 1,
    tokenName: 'MX',
    stakingTokenName: QuoteToken.MX,
    stakingTokenAddress: '0x9F882567A62a5560d147d64871776EeA72Df41D3',
    contractAddress: {
      56: '0x63621A878f6eae37DC5BdEDB2B828D9E3747b0f4',
      97: '0xd388A7BA0e7CF5833304E9fbe824107Efa40f2f1',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://pureswap.finance/',
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 2,
    isFinished: false,
    tokenDecimals: 18,
    getPriceTokenSymbol: 'mx-token',
  },
  {
    sousId: 2,
    tokenName: 'WBNB',
    stakingTokenName: QuoteToken.WBNB,
    stakingTokenAddress: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    contractAddress: {
      56: '0x63621A878f6eae37DC5BdEDB2B828D9E3747b0f4',
      97: '0xd388A7BA0e7CF5833304E9fbe824107Efa40f2f1',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://pureswap.finance/',
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 2,
    isFinished: false,
    tokenDecimals: 18,
    getPriceTokenSymbol: 'binancecoin',
  },
  {
    sousId: 3,
    tokenName: 'BUSD',
    stakingTokenName: QuoteToken.BUSD,
    stakingTokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    contractAddress: {
      56: '0x63621A878f6eae37DC5BdEDB2B828D9E3747b0f4',
      97: '0xd388A7BA0e7CF5833304E9fbe824107Efa40f2f1',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://pureswap.finance/',
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 2,
    isFinished: false,
    tokenDecimals: 18,
    getPriceTokenSymbol: 'binance-usd',
  },
  {
    sousId: 4,
    tokenName: 'BTCB',
    stakingTokenName: QuoteToken.BTCB,
    stakingTokenAddress: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    contractAddress: {
      56: '0x63621A878f6eae37DC5BdEDB2B828D9E3747b0f4',
      97: '0xd388A7BA0e7CF5833304E9fbe824107Efa40f2f1',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://pureswap.finance/',
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 2,
    isFinished: false,
    tokenDecimals: 18,
    getPriceTokenSymbol: 'binance-bitcoin',
  }
]

export default pools
